import {
  Directive,
  ElementRef,
  HostListener,
  Input,
} from '@angular/core';
import {CopyPasteKeys, NavigationKeys} from '@ideals/types';

const VALID_DECIMAL_SEPARATOR = '.';

@Directive({
  selector: '[idealsDigitOnly]',
})
export class DigitOnlyDirective {
  @Input()
  public decimal = true;

  @Input()
  public decimalSeparators = '.,';

  @Input()
  public digitOnlyDisabled = false;

  private readonly _inputElement: HTMLInputElement;

  constructor(public el: ElementRef) {
    this._inputElement = el.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  public onKeyDown(e: KeyboardEvent): void {
    if (this.digitOnlyDisabled) return;

    const isNavigationKey = NavigationKeys[e.key];
    const isCopyPasteKeys = CopyPasteKeys[e.keyCode] && (e.ctrlKey === true || e.metaKey === true);
    const isDecimalKeyAllowed = this.decimal && this._valueContainSeparator(e.key) && !this._hasDecimalPoint;
    const isKeyAllowed = isNavigationKey || isCopyPasteKeys || isDecimalKeyAllowed;

    if (isKeyAllowed) {
      return;
    }

    const keyIsNotNumber = e.key === ' ' || isNaN(Number(e.key));

    if (keyIsNotNumber) {
      e.preventDefault();
    }
  }

  @HostListener('keyup', ['$event'])
  public onKeyUp(e: KeyboardEvent): void {
    if (this.digitOnlyDisabled || !this.decimal) return;

    this._setValidDecimalSeparator();
  }

  @HostListener('paste', ['$event'])
  public onPaste(event: any): void {
    if (this.digitOnlyDisabled) return;

    let pastedInput;
    if (window['clipboardData']) {
      // Browser is IE
      pastedInput = window['clipboardData'].getData('text');
    } else if (event.clipboardData && event.clipboardData.getData) {
      pastedInput = event.clipboardData.getData('text/plain');
    }

    this._pasteData(pastedInput);
    event.preventDefault();
  }

  @HostListener('drop', ['$event'])
  public onDrop(event: any): void {
    if (this.digitOnlyDisabled) return;

    const textData = event.dataTransfer.getData('text');
    this._inputElement.focus();
    this._pasteData(textData);
    event.preventDefault();
  }

  private get _hasDecimalPoint(): boolean {
    return this._valueContainSeparator(this._inputElement.value);
  }

  private get _selection(): string {
    const {selectionStart, selectionEnd, value} = this._inputElement;

    return value.substring(selectionStart, selectionEnd);
  }

  private _valueContainSeparator(value: string): boolean {
    return value && this.decimalSeparators
      .split('')
      .some((separator) => value.indexOf(separator) > -1);
  }

  private _pasteData(pastedContent: string): void {
    const sanitizedContent = this._sanitizeInput(pastedContent);
    const pasted = document.execCommand('insertText', false, sanitizedContent);
    if (!pasted) {
      if (this._inputElement.setRangeText) {
        const {selectionStart, selectionEnd} = this._inputElement;
        this._inputElement.setRangeText(sanitizedContent, selectionStart, selectionEnd, 'end');
      } else {
        // Browser does not support setRangeText, e.g. IE
        this._insertAtCursor(sanitizedContent);
      }
    }
    if (this.decimal) {
      this._setValidDecimalSeparator();
    }
  }

  private _insertAtCursor(content: string): void {
    const {selectionStart, selectionEnd, value} = this._inputElement;
    const start = value.substring(0, selectionStart);
    const end = value.substring(selectionEnd, value.length);

    this._inputElement.value = `${start}${content}${end}`;

    const pos = selectionStart + content.length;
    this._inputElement.focus();
    this._inputElement.setSelectionRange(pos, pos);

    this._triggerEvent();
  }

  private _triggerEvent(): void {
    if ('createEvent' in document) {
      const e = document.createEvent('HTMLEvents');
      e.initEvent('input', false, true);
      this._inputElement.dispatchEvent(e);
    }
  }

  private _sanitizeInput(input: string): string {
    let result = '';
    if (this.decimal && this._isValidDecimal(input)) {
      const regex = new RegExp(`[^0-9${this.decimalSeparators}]`, 'g');
      result = input.replace(regex, '');
    } else {
      result = input.replace(/[^0-9]/g, '');
    }

    const {maxLength, value} = this._inputElement;
    if (maxLength && maxLength > 0) {
      const allowedLength = maxLength - value.length;
      result = allowedLength > 0 ? result.substring(0, allowedLength) : '';
    }

    return result;
  }

  private _isValidDecimal(value: string): boolean {
    const decimalParts = 2;

    const validDecimal = !this._hasDecimalPoint || (this._selection && this._valueContainSeparator(this._selection));

    if (validDecimal) {
      const regex = new RegExp(`[${this.decimalSeparators}]`, 'g');

      return value.split(regex).length <= decimalParts;
    }

    return !this._valueContainSeparator(value);
  }

  private _setValidDecimalSeparator(): void {
    const regex = new RegExp(`[^0-9${VALID_DECIMAL_SEPARATOR}]`, 'g');
    this._inputElement.value = this._inputElement.value.replace(regex, VALID_DECIMAL_SEPARATOR);
    this._triggerEvent();
  }
}
