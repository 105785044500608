/* eslint-disable camelcase */
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, ViewEncapsulation } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

import { IdealsDialogModule } from '@ideals/services/dialog';
import { OpenMailNewTabModule } from '@ideals/ui-components/open-mail-new-tab';
import { SkeletonModule } from '@ideals/ui-components/skeleton';
import { TooltipModule } from '@ideals/ui-components/tooltip';
import { Utils } from '@ideals/utils';

import { IntercomModule } from '../../../intercom';
import { HELP_UTM_CAMPAIGN, HELP_UTM_SOURCE } from '../../models/constants';
import { IVideo } from '../../models/interfaces';
import { HelpStore } from '../../store/help.store';
import { HelpArticleComponent } from '../help-article/help-article.component';
import { HelpContactsComponent } from '../help-contacts/help-contacts.component';
import { HelpSearchComponent } from '../help-search/help-search.component';
import { HelpVideoItemComponent } from '../help-video-item/help-video-item.component';

export const SUPPORT_EMAIL = 'support@idealsvdr.com';
const ARTICLE_SKELETON_ITEMS_AMOUNT = 5;
const VIDEO_SKELETON_ITEMS_AMOUNT = 2;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    AsyncPipe,
    HelpArticleComponent,
    HelpContactsComponent,
    HelpSearchComponent,
    HelpVideoItemComponent,
    IdealsDialogModule,
    IntercomModule,
    OpenMailNewTabModule,
    SkeletonModule,
    TooltipModule,
    TranslateModule,
  ],
  standalone: true,
  styleUrls: ['./help-modal.component.scss'],
  templateUrl: './help-modal.component.html',
})
export class HelpModalComponent {
  readonly #helpStore = inject(HelpStore);
  readonly #translateService = inject(TranslateService);

  protected readonly articles = this.#helpStore.filteredArticles;
  protected readonly articlesLoading = this.#helpStore.articlesLoading;
  protected readonly videos = this.#helpStore.filteredVideos;
  protected readonly videosLoading = this.#helpStore.videosLoading;

  protected readonly articleSkeletons = Array(ARTICLE_SKELETON_ITEMS_AMOUNT);
  protected readonly supportEmail = SUPPORT_EMAIL;
  protected readonly videoSkeletons = Array(VIDEO_SKELETON_ITEMS_AMOUNT);

  protected video: IVideo;

  get modalTitle$(): Observable<string> {
    return this.video
      ? of(this.video.title)
      : this.#translateService.get('help_modal.HEADER.welcome_to_help_center');
  }

  protected closeVideo(): void {
    this.video = undefined;
  }

  protected openVideo(video: IVideo): void {
    this.video = video;
  }

  protected urlWithUTMTags(url: string): string {
    const params = {
      utm_source: HELP_UTM_SOURCE,
      utm_medium: this.#helpStore.appPage(),
      utm_campaign: HELP_UTM_CAMPAIGN.RECOMMENDED,
    };

    return Utils.objectToQueryString(params, url);
  }
}
