import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';

import { LOCATION } from '@ideals/types';

@Injectable({ providedIn: 'root' })
export class DnsPrefetchService {
  readonly #document = inject(DOCUMENT);
  readonly #location = inject(LOCATION);

  createLinks(): void {
    const currentOrigin = this.#location.origin;

    if (currentOrigin.includes('auth.idealsvdr') || currentOrigin.includes('stagevdr')) {
      const appOrigin = currentOrigin.replace('auth', 'app');

      this.#createDnsPrefetch(`${appOrigin}/`);
    }
  }

  #createDnsPrefetch(url: string): void {
    const link = this.#document.createElement('link');

    link.rel = 'dns-prefetch';
    link.href = url;
    this.#document.head.appendChild(link);
  }
}
