import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { DownloadService } from '@ideals/services/download';
import { APP_CONFIG, IStringMap, LOCATION } from '@ideals/types';

import { HelpCountry } from './models/classes';
import { IHelpCountries } from './models/interfaces';
import { Articles, HelpCountries, SearchArticles, Videos } from './models/types';

import { HelpAuthDataService } from './help-auth-data.service';

@Injectable({
  providedIn: 'root',
})
export class HelpService {
  readonly #appConfig = inject(APP_CONFIG);
  readonly #authDataService = inject(HelpAuthDataService);
  readonly #downloadService = inject(DownloadService);
  readonly #httpClient = inject(HttpClient);
  readonly #location = inject(LOCATION);
  readonly #translateService = inject(TranslateService);

  readonly #appUrl = this.#appConfig.appUrl;
  readonly #helpCenterUrl = this.#appConfig.helpCenterUrl;

  get #currentLang(): string {
    return this.#translateService.currentLang;
  }

  downloadArticles(): Observable<Blob> {
    const url = `${this.#helpCenterUrl}download/${this.#currentLang}/help.html`;
    const fileName = 'iDealsManual.html';

    return this.#downloadService.downloadFile(url, fileName);
  }

  getArticles(): Observable<Articles> {
    return this.#httpClient.get<{ content: Articles; }>(`${this.#helpCenterUrl}content/${this.#currentLang}/content.json`).pipe(
      map(({ content }) => content),
      catchError(() => EMPTY),
    );
  }

  getCountryPhonesMapping(): Observable<IStringMap> {
    return this.#httpClient.get<IStringMap>(`${this.#helpCenterUrl}countries/map-phones.json`).pipe(
      catchError(() => of({})),
    );
  }

  getHelpCountries(): Observable<HelpCountries> {
    return this.#httpClient.get<IHelpCountries>(`${this.#helpCenterUrl}countries/${this.#currentLang}/content.json`).pipe(
      map(({ countries }) => countries.map((country) => new HelpCountry(country))),
      catchError(() => EMPTY),
    );
  }

  getSearchIndex(): Observable<SearchArticles> {
    return this.#httpClient.get<SearchArticles>(`${this.#helpCenterUrl}indexes/${this.#currentLang}/index.json`).pipe(
      catchError(() => EMPTY),
    );
  }

  getVideos(): Observable<Videos> {
    return this.#httpClient.get<{ videos: Videos; }>(`${this.#helpCenterUrl}videos/videos.json`).pipe(
      map(({ videos }) => videos.map(({ sources, ...rest }) => ({
        ...rest,
        sources: sources.map(({ src, type }) => ({
          src: `${this.#helpCenterUrl}${src}`,
          type,
        })),
      }))),
      catchError(() => EMPTY),
    );
  }

  logSearchStatistics(searchQueue: string, countSearchResults: number): Observable<void> {
    if (!this.#authDataService.isLoggedIn || this.#authDataService.isIdealsUser) {
      return of();
    }

    const body = {
      searchQueue,
      countSearchResults,
      url: this.#location.href,
    };

    return this.#httpClient.post<void>(`${this.#appUrl}/api/stats/add`, body).pipe(
      catchError(() => EMPTY),
    );
  }
}
