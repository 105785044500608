import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { LOGIN_PATH } from '../../../types';

export function userStateErrorsInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const router = inject(Router);

  return next(request).pipe(
    catchError((err: HttpErrorResponse) => {
      const { error } = err as { error: { state: string; }; };
      const state = error?.state;

      if (state) {
        router.navigate([`/${LOGIN_PATH}`]);

        return EMPTY;
      }

      return throwError(() => err);
    })
  );
}
