<div
  class="help-modal__header"
  [class.video]="video"
  mat-dialog-title
  data-ideals="helpModalHeader"
>
  <button
    [attr.aria-label]="'Close' | translate"
    class="close icons-cross"
    [mat-dialog-close]="false"
    type="button"
    data-ideals="dismissModal"
  >
  </button>

  @if (video) {
    <button
      class="back icons-left theme-color"
      type="button"
      (click)="closeVideo()"
      data-ideals="backFromVideo"
    >
    </button>
  }

  @let title = modalTitle$ | async;
  <h4
    class="modal-title"
    [ideals-tooltip]="title"
    idealsOverflow
  >
    {{ title }}
  </h4>
</div>

<div
  class="help-modal__container"
  [class.video]="video"
  mat-dialog-content
  data-ideals="helpModalContainer"
>
  @if (video) {
    <video
      autoplay
      class="help-modal__video"
      controls
      disablePictureInPicture
    >
      @for (source of video.sources; track $index) {
        <source [src]="source.src" [type]="source.type"/>
      }
    </video>
  } @else {
    <ideals-help-search/>

    <div class="help-modal__content">
      <section class="help-modal__section">
        <h3 class="help-modal__section--header">{{ 'help_modal.TEXT.popular_topics' | translate }}</h3>
        <div class="help-modal__section--content">
          @if (articlesLoading()) {
            @for (skeleton of articleSkeletons; track $index) {
              <ideals-skeleton height="24px" styleClass="mb-2"/>
            }
          } @else {
            @for (article of articles(); track $index) {
              <ideals-help-article [title]="article.title" [url]="urlWithUTMTags(article.url)"/>
            }
          }
        </div>
      </section>

      @if (videosLoading()) {
        <div class="help-modal__section loading">
          @for (skeleton of videoSkeletons; track $index) {
            <div class="video-skeleton">
              <ideals-skeleton
                class="video-icon"
                height="58px"
                width="88px"
              />
              <ideals-skeleton class="video-title" height="32px"/>
            </div>
          }
        </div>
      } @else {
        @if (videos().length) {
          <section class="help-modal__section">
            <h3 class="help-modal__section--header">{{ 'help_modal.TEXT.featured_videos' | translate }}</h3>
            <div class="help-modal__section--content">
              @for (video of videos(); track $index) {
                <ideals-help-video-item
                  class="help-modal__video-item"
                  [video]="video"
                  (openVideo)="openVideo($event)"
                />
              }
            </div>
          </section>
        }
      }
    </div>

    <div class="help-modal__footer">
      <h2 class="help-modal__footer--title">
        {{ 'help_modal.TEXT.support_team_24/7' | translate }}
      </h2>
      <div class="help-modal__footer--buttons">
        <button class="help-modal__footer--button chat theme-bg-color--on-hover" idealsShowChat>
          <i class="icon icons-chat"></i>
          <div class="text">
            <p class="title theme-color--no-hover">{{'help_modal.BTN.chat_with_us' | translate}}</p>
            <p> {{ 'help_modal.TEXT.response_time_30sec' | translate }} </p>
          </div>
        </button>
        <div class="help-modal__footer--button email">
          <i class="icon icons-letter"></i>
          <div>
            <a
              class="title theme-color"
              href="mailto:{{supportEmail}}"
              idealsOpenMailNewTab
            >
              {{ supportEmail }}
            </a>
            <p>{{ 'help_modal.TEXT.response_time_30min' | translate }}</p>
          </div>
        </div>
        <ideals-help-contacts class="help-modal__footer--button contacts"/>
      </div>
    </div>
  }
</div>
