@if (loading()) {
  <ideals-skeleton class="skeleton" height="54px"/>
} @else {
  <div class="help-contacts" data-ideals="helpContacts">
    <ng-select
      #countriesSelect
      appendTo="body"
      class="help-contacts__countries ng-select__arrow--fixed ideals-select__theme--colored-arrow ideals-select__theme--colored-value"
      [clearable]="false"
      [dropdownPosition]="dropdownPosition$ | async"
      [groupBy]="countriesGroupByField"
      [items]="countries()"
      [ngModel]="selectedCountry()"
      [openOnEnter]="false"
      [searchable]="false"
      [searchFn]="searchCountry"
      [searchWhileComposing]="false"
      (change)="selectCountry($event)"
      (close)="onSelectClose()"
      (open)="onSelectOpen()"
      data-ideals="helpContactsSelect"
    >
      <ng-template ng-label-tmp let-item="item">
        <div class="help-contacts__selected-country">
          <i class="icons-phone"></i>
          <span
            class="value"
            [ideals-tooltip]="selectedCountry().countryName"
            idealsOverflow
          >
            {{ selectedCountry().countryName }}
          </span>
        </div>
      </ng-template>

      <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
        <div class="help-contacts__country">
          <span class="help-contacts__country-flag" [ngClass]="item.countryCode"></span>
          <span class="country-name">{{ item.countryName }}</span>
          <span class="country-phone-code">({{ item.phoneCode }})</span>
        </div>
      </ng-template>

      <ng-template ng-header-tmp>
        <div class="help-contacts__search" data-ideals="helpContactsSearch">
          <label
            class="help-contacts__search--label icons-search"
            for="search"
            data-ideals="helpContactsSearchLabel"
          >
          </label>
          <input
            autocomplete="off"
            class="help-contacts__search--input"
            id="search"
            idealsAutofocus
            type="text"
            (input)="countriesSelect.filter($event?.target?.value)"
            data-ideals="helpContactsSearchInput"
          />
        </div>
      </ng-template>
      <ng-template ng-footer-tmp>
        <div class="help-contacts__search" data-ideals="helpContactsSearch">
          <label
            class="help-contacts__search--label icons-search"
            for="search"
            data-ideals="helpContactsSearchLabel"
          >
          </label>
          <input
            autocomplete="off"
            class="help-contacts__search--input"
            id="search"
            idealsAutofocus
            type="text"
            (input)="countriesSelect.filter($event?.target?.value)"
            data-ideals="helpContactsSearchInput"
          />
        </div>
      </ng-template>
      <ng-template ng-notfound-tmp let-searchTerm="searchTerm"/>
    </ng-select>
    
    @if (selectedCountry()) {
      <p class="help-contacts__phones">
        @for (phone of selectedCountry().contacts | idealsSplit; track $index) {
          <span
            [ideals-tooltip]="selectedCountry().contacts"
            idealsOverflow
            [tooltipWidthAuto]="true"
          >
            {{ phone }}
          </span>
        }
      </p>
    }
  </div>
}
