<a
  class="help-button"
   href="#"
   (click)="openHelpModal($event)"
   data-ideals="showHelpModal"
>
  <span #content class="help-button__custom">
    <ng-content></ng-content>
  </span>

  @if (!content.childNodes.length) {
    <i class="icon icons-question"></i>
    <span class="title">{{ 'common.BTN.help' | translate }}</span>
  }
</a>
