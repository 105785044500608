import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {CheckboxModule} from './checkbox/checkbox.module';
import {InputModule} from './input/input.module';
import {SelectModule} from './select/select.module';
import {TooltipModule} from './tooltip/tooltip.module';

const modules = [
  TooltipModule,
  InputModule,
  CheckboxModule,
  SelectModule,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TooltipModule,
    TranslateModule,
    ReactiveFormsModule,
  ],
  exports: [...modules],
})
export class UiComponentsModule {
}
