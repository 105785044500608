import { inject } from '@angular/core';

import { HelpStore } from '@ideals/partial-views/help';

export function loginPagesResolver(): boolean {
  const store = inject(HelpStore);

  store.setAppPage('login');

  return true;
}
