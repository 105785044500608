<div
  class="help-search"
  [class.active]="searchPopup.isPopupOpened"
  [idealsPopup]="searchPopup"
  [popupBoundaries]="popupBoundaries"
  [popupCloseOnToggle]="false"
  [popupDisableStyle]="true"
  [popupPlacement]="popupPlacement"
  data-ideals="helpSearch"
>
  <div class="help-search__input--wrapper">
    <label class="help-search__input--icon icons-search" for="search-input"></label>
    <input
      #searchInput
      autocomplete="off"
      class="help-search__input"
      id="search-input"
      maxlength="100"
      [ngModel]="searchQuery()"
      [placeholder]="'help_modal.PLACEHOLDER.search_knowledge_base' | translate"
      type="text"
      (ngModelChange)="onSearchChange($event)"
      data-ideals="input"
    />

    @if (searchQuery()) {
      <i
        class="help-search__input--icon icons-cross"
        (click)="reset(); searchInput.focus()"
        data-ideals="inputClear"
      >
      </i>
    }
  </div>

  <ideals-popup-content
    #searchPopup
    class="help-search__dropdown"
    [hideArrow]="true"
  >
    <div class="help-search__header">
      <span class="help-search__header--no-results" [innerHTML]="searchHeader() | translate: searchHeaderParams"></span>

      @if (emptyData()) {
        <p class="help-search__header--empty">
          {{ 'help_modal.MSG.rephrase_query_or_chat' | translate }}

          @if (intercomChat) {
            <a
              class="open-chat"
              idealsShowChat
              data-ideals="openChat"
            >
              <i class="open-chat__icon icons-chat"></i>
              {{ 'help_modal.BTN.chat_with_us_now' | translate }}
            </a>
          }
        </p>
      }
    </div>

    <div class="help-search__content">
      @for (article of articles(); track $index) {
        <ideals-help-article [title]="article.title" [url]="urlWithUTMTags(article.url)"/>
      }
    </div>

    <div class="help-search__footer">
      <a
        class="help-search__footer--link"
        [href]="urlWithUTMTags('help_modal.URL.visit_knowledge_base' | translate, true)"
        target="_blank"
        data-ideals="visitKnowledgeBase"
      >
        <i class="icon icons-open-link"></i>
        {{ 'help_modal.LINK.visit_knowledge_base' | translate }}
      </a>

      @if (isDownloadBtnVisible) {
        <a
          class="help-search__footer--link"
          href="#"
          (click)="downloadArticles($event)"
          data-ideals="downloadArticles"
        >
          <i class="icon icons-download"></i>
          {{ 'help_modal.LINK.download_all_articles' | translate }}
        </a>
      }
    </div>
  </ideals-popup-content>
</div>
