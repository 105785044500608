import { ChangeDetectionStrategy, Component, inject, ViewEncapsulation } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { IdealsDialogService } from '@ideals/services/dialog';

import { HELP_MODAL_DEFAULT_CONFIG } from '../../models/constants';
import { HelpStore } from '../../store/help.store';
import { HelpModalComponent } from '../help-modal/help-modal.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [TranslateModule],
  standalone: true,
  selector: 'ideals-help-button',
  styleUrls: ['./help-button.component.scss'],
  templateUrl: './help-button.component.html',
})
export class HelpButtonComponent {
  readonly #dialogService = inject(IdealsDialogService);
  readonly #helpStore = inject(HelpStore);
  readonly #translateService = inject(TranslateService);

  openHelpModal(event: MouseEvent): void {
    event?.preventDefault();
    this.#helpStore.loadHelpData({ language: this.#translateService.currentLang });

    this.#dialogService.open(
      HelpModalComponent,
      HELP_MODAL_DEFAULT_CONFIG
    );
  }
}
