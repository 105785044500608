import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { BrandingService } from '@ideals/services/branding';
import { IRoomLoginInfo } from '@ideals/types';

export function roomLoginInfoResolver(): Observable<IRoomLoginInfo> {
  const brandingService = inject(BrandingService);

  return brandingService.getLogotypeWithRoomName().pipe(first());
}
