import {Injectable} from '@angular/core';
import {UntypedFormControl, FormGroupDirective, NgForm} from '@angular/forms';
import {IErrorStateMatcherService} from '@ideals/types';

@Injectable({providedIn: 'root'})
/**
 * @deprecated never use
 */
export class ErrorStateMatcherService implements IErrorStateMatcherService {
  public isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && form && form.submitted);
  }
}
