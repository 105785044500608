import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { PageMessagesService } from '@ideals/partial-views/page-messages';

import { AUTO_CLOSE_TIMEOUT } from '../../../types';

const openedAlerts = new Map<string, string>();

enum HttpStatusNoConnectionCode {
  NoConnection = 0,
}

export function serverErrorsInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const pageMessages = inject(PageMessagesService);

  return next(request).pipe(
    tap((event) => {
      const url = request.url;

      if (event instanceof HttpResponse && openedAlerts.has(url)) {
        const alert = pageMessages.messages.find((message) => message.caption === openedAlerts.get(url));

        if (alert) {
          pageMessages.close(alert);
          openedAlerts.delete(url);
        }
      }
    }),
    catchError((error: HttpErrorResponse) => {
      const { status } = error;
      let errorMessage: string | undefined = undefined;

      switch (status as HttpStatusNoConnectionCode | HttpStatusCode) {
        case HttpStatusNoConnectionCode.NoConnection:
          errorMessage = 'common.ERROR.check_your_internet_connection';
          break;

        case HttpStatusCode.InternalServerError:
        case HttpStatusCode.NotImplemented:
        case HttpStatusCode.BadGateway:
        case HttpStatusCode.ServiceUnavailable:
        case HttpStatusCode.GatewayTimeout:
          errorMessage = 'common.ERROR.your_request_cannot_be_processed';
          break;

        default:
          break;
      }

      if (errorMessage) {
        const url = request.url;

        openedAlerts.set(url, errorMessage);
        pageMessages.error({
          text: errorMessage,
          timeToClose: AUTO_CLOSE_TIMEOUT,
        });

        setTimeout(() => {
          openedAlerts.delete(url);
        }, AUTO_CLOSE_TIMEOUT);
      }

      return throwError(() => error);
    })
  );
}
