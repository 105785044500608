<a
  class="help-article theme-color--on-hover"
  [href]="url()"
  target="_blank"
  data-ideals="helpArticle"
>
  <span
    class="title"
    [ideals-tooltip]="title"
    idealsOverflow
  >
    {{ title() }}
  </span>
  <i class="icon icons-open-link theme-color"></i>
</a>
