import {animate, style, transition, trigger} from '@angular/animations';

export const transitionErrors = trigger('transitionErrors', [
  transition(':enter', [
    style({
      position: 'relative',
      opacity: 0,
      top: '-5px'
    }),
    animate(
      '300ms cubic-bezier(0.55, 0, 0.55, 0.2)',
      style({
        opacity: 1,
        top: '0px'
      })
    )
  ])
]);
