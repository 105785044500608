import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {NgxMaskDirective, provideNgxMask} from 'ngx-mask';
import {AutosizeModule} from '../autosize/autosize.module';
import {DigitOnlyModule} from '../digit-only/digit-only.module';
import {InputControlWrapperModule} from '../input-control-wrapper/input-control-wrapper.module';
import {InputComponent} from './input.component';
import {InputDirective} from './input.directive';

const declarations = [InputComponent, InputDirective];

@NgModule({
  declarations: declarations,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    TranslateModule,
    AutosizeModule,
    InputControlWrapperModule,
    DigitOnlyModule,
  ],
  exports: [...declarations, ReactiveFormsModule],
  providers: [provideNgxMask()]
})
export class InputModule {
}
