import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AsyncPipe, DOCUMENT, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnDestroy,
  Pipe,
  PipeTransform,
  Renderer2,
  viewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { CookieService } from 'ngx-cookie-service';
import { map, startWith } from 'rxjs/operators';

import { Position, WINDOW } from '@ideals/types';
import { TooltipModule } from '@ideals/ui-components';
import { AutofocusModule } from '@ideals/ui-components/autofocus';
import { SkeletonModule } from '@ideals/ui-components/skeleton';
import { Utils } from '@ideals/utils';

import { HelpCountry } from '../../models/classes';
import { COUNTRY_CODE_COOKIE } from '../../models/constants';
import { IHelpCountry } from '../../models/interfaces';
import { HelpStore } from '../../store/help.store';

export const COUNTRIES_GROUP_BY_FIELD = 'regionName';
const DROPDOWN_PANEL_SELECTOR = '.ng-dropdown-panel';
const SCROLLED_CLASS = 'scrolled';

@Pipe({
  name: 'idealsSplit',
  standalone: true,
})
export class SplitPipe implements PipeTransform {
  transform(value: string): string[] {
    return value?.split(/\n/) ?? [];
  }
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    AsyncPipe,
    AutofocusModule,
    FormsModule,
    NgClass,
    NgSelectModule,
    SkeletonModule,
    SplitPipe,
    TooltipModule,
  ],
  selector: 'ideals-help-contacts',
  standalone: true,
  styleUrls: ['./help-contacts.component.scss'],
  templateUrl: './help-contacts.component.html',
})
export class HelpContactsComponent implements OnDestroy {
  protected readonly countriesSelect = viewChild<NgSelectComponent>('countriesSelect');

  readonly #breakpointObserver = inject(BreakpointObserver);
  readonly #cookieService = inject(CookieService);
  readonly #document = inject(DOCUMENT);
  readonly #helpStore = inject(HelpStore);
  readonly #renderer = inject(Renderer2);
  readonly #window = inject(WINDOW);

  readonly #adjustDropdownPosition = (): void => {
    this.countriesSelect()?.dropdownPanel.adjustPosition();
  };
  readonly #eventScrollOptions = { capture: true, passive: true };
  readonly #hideDropdown = (): void => {
    this.countriesSelect()?.close();
  };

  protected readonly countries = this.#helpStore.countries;
  protected readonly loading = this.#helpStore.countriesLoading;
  protected readonly selectedCountry = this.#helpStore.selectedCountry;

  protected readonly dropdownPosition$ = this.#breakpointObserver.observe('(min-width: 1280px)').pipe(
    map((state: BreakpointState) => state.matches ? 'bottom' : 'auto'),
    startWith('auto')
  );

  protected readonly countriesGroupByField = COUNTRIES_GROUP_BY_FIELD;

  ngOnDestroy(): void {
    this.#removeWindowListeners();
  }

  onSelectClose(): void {
    this.#removeWindowListeners();
  }

  protected onSelectOpen(): void {
    if (this.countriesSelect()) {
      setTimeout(() => {
        const maxElementHeight = 269;
        const { dropdownPanel: { scrollElementRef } } = this.countriesSelect();
        const nativeElement = scrollElementRef.nativeElement as HTMLElement;

        if (this.countriesSelect().currentPanelPosition === Position.Bottom) {
          const dropdownTop = nativeElement.getBoundingClientRect().top;
          const bottomMargin = 5;
          const heightToBottom = this.#window.innerHeight - dropdownTop - bottomMargin;
          const maxHeight = Math.min(maxElementHeight, heightToBottom);

          nativeElement.style.setProperty('max-height', `${maxHeight}px`, 'important');
        } else {
          nativeElement.style.setProperty('max-height', `${maxElementHeight}px`, 'important');
        }

        nativeElement.scrollTop = 0;
        this.#addWindowListeners();

        const panel = this.#document.querySelector(DROPDOWN_PANEL_SELECTOR);

        this.#renderer.addClass(panel, SCROLLED_CLASS);
      }, 0);
    }
  }

  protected searchCountry(term: string, { countryName, phoneCode }: IHelpCountry): boolean {
    const query = term.toLocaleLowerCase();
    const value = `${countryName}${phoneCode}`.toLocaleLowerCase();

    return value.includes(query);
  }

  protected selectCountry(selectedCountry: IHelpCountry): void {
    if (selectedCountry instanceof HelpCountry) {
      this.#cookieService.set(COUNTRY_CODE_COOKIE, selectedCountry.countryCode, undefined, '/', Utils.domain, true);
      this.#helpStore.selectCountry(selectedCountry);
    }
  }

  #addWindowListeners(): void {
    this.#window.addEventListener('scroll', this.#adjustDropdownPosition, this.#eventScrollOptions);
    // adjustPosition does not update X axis
    this.#window.addEventListener('orientationchange', this.#hideDropdown);
  }

  #removeWindowListeners(): void {
    this.#window.removeEventListener('scroll', this.#adjustDropdownPosition, this.#eventScrollOptions);
    this.#window.removeEventListener('orientationchange', this.#hideDropdown);
  }
}
