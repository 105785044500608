import {AfterContentChecked, Directive, ElementRef, Inject, Input, OnDestroy} from '@angular/core';
import {CssPropertiesNames, CssPropertiesValues, WINDOW} from '@ideals/types';
import {Utils} from '@ideals/utils';

@Directive({
  selector: '[idealsAutosize]'
})

export class AutosizeDirective implements OnDestroy, AfterContentChecked {
  @Input()
  public set minRows(value: number) {
    this._minRows = value;
    if (this._textAreaEl) {
      this._textAreaEl.rows = value;
    }
  }

  @Input()
  public maxRows: number;
  @Input()
  public onlyGrow = false;
  @Input()
  public useImportant = false;

  private _minRows: number;
  private _oldContent: string;
  private _oldWidth: number;
  private _destroyed = false;

  private readonly _textAreaEl: HTMLTextAreaElement;

  constructor(
    private readonly _element: ElementRef,
    @Inject(WINDOW) private readonly _window: Window
  ) {
    this._textAreaEl = this._element.nativeElement as HTMLTextAreaElement;
    this._textAreaEl.style.overflow = CssPropertiesValues.hidden;
  }

  public ngAfterContentChecked(): void {
    this.adjust();
  }

  public ngOnDestroy(): void {
    this._destroyed = true;
  }

  public adjust(inputsChanged: boolean = false): void {
    if (!this._destroyed && this._textAreaEl) {

      const currentText = this._textAreaEl.value;

      if (inputsChanged === false && currentText === this._oldContent && this._textAreaEl.offsetWidth === this._oldWidth) {
        return;
      }

      this._oldContent = currentText;
      this._oldWidth = this._textAreaEl.offsetWidth;

      const clone = this._textAreaEl.cloneNode(true) as HTMLTextAreaElement;
      const parent = this._textAreaEl.parentNode;
      clone.style.width = `${this._textAreaEl.offsetWidth}px`;
      clone.style.visibility = CssPropertiesValues.hidden;
      clone.style.position = CssPropertiesValues.absolute;
      clone.textContent = currentText;

      parent.appendChild(clone);

      clone.style.overflow = CssPropertiesValues.auto;
      clone.style.height = CssPropertiesValues.auto;

      let height = clone.scrollHeight;

      // add into height top and bottom borders' width
      const computedStyle = this._window.getComputedStyle(clone, undefined);

      height += Utils.parserIntWithRadix10(computedStyle.getPropertyValue(CssPropertiesNames.borderTopWidth));
      height += Utils.parserIntWithRadix10(computedStyle.getPropertyValue(CssPropertiesNames.borderBottomWidth));

      const oldHeight = this._textAreaEl.offsetHeight;
      const willGrow = height > oldHeight;
      const isGrow = this.onlyGrow === false || willGrow;

      if (isGrow) {
        const lineHeight = this._getLineHeight();
        const rowsCount = height / lineHeight;

        if (this._minRows && this._minRows >= rowsCount) {
          height = this._minRows * lineHeight;

        } else if (this.maxRows && this.maxRows <= rowsCount) {
          // never shrink the textarea if onlyGrow is true
          const maxHeight = this.maxRows * lineHeight;
          height = this.onlyGrow ? Math.max(maxHeight, oldHeight) : maxHeight;
          this._textAreaEl.style.overflow = CssPropertiesValues.auto;

        } else {
          this._textAreaEl.style.overflow = CssPropertiesValues.hidden;
        }

        this._textAreaEl.style.height = `${height}px${this.useImportant ? ' !important' : ''}`;
      }

      parent.removeChild(clone);
    }
  }

  private _getLineHeight(): number {
    let lineHeight = Utils.parserIntWithRadix10(this._textAreaEl.style.lineHeight);
    if (isNaN(lineHeight) && this._window.getComputedStyle) {
      const styles = this._window.getComputedStyle(this._textAreaEl);
      lineHeight = Utils.parserIntWithRadix10(styles.lineHeight);
    }

    return lineHeight;
  }
}
