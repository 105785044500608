import { Injectable } from '@angular/core';

interface HelpAuthData {
  readonly isIdealsUser: boolean;
  readonly isLoggedIn: boolean;
}

@Injectable({ providedIn: 'root' })
export class HelpAuthDataService {
  #isIdealsUser = false;
  #isLoggedIn = false;

  get isIdealsUser(): boolean {
    return this.#isIdealsUser;
  }

  get isLoggedIn(): boolean {
    return this.#isLoggedIn;
  }

  updateData({ isLoggedIn, isIdealsUser }: HelpAuthData): void {
    this.#isLoggedIn = isLoggedIn;
    this.#isIdealsUser = isIdealsUser;
  }
}
