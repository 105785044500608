import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { TooltipModule } from '@ideals/ui-components/tooltip';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TooltipModule],
  selector: 'ideals-help-article',
  standalone: true,
  styleUrls: ['./help-article.component.scss'],
  templateUrl: './help-article.component.html',
})
export class HelpArticleComponent {
  readonly title = input<string>();
  readonly url = input<string>();
}
