import { ChangeDetectionStrategy, Component, input, output, ViewEncapsulation } from '@angular/core';

import { TooltipModule } from '@ideals/ui-components/tooltip';

import { IVideo } from '../../models/interfaces';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [TooltipModule],
  selector: 'ideals-help-video-item',
  standalone: true,
  styleUrls: ['./help-video-item.component.scss'],
  templateUrl: './help-video-item.component.html',
})
export class HelpVideoItemComponent {
  readonly video = input<IVideo>();

  readonly openVideo = output<IVideo>();

  onVideoClick(event: MouseEvent): void {
    event?.preventDefault();
    this.openVideo.emit(this.video());
  }
}
