import {AfterContentInit, Component, Input, OnDestroy, OnInit, Optional, ViewEncapsulation} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';
import {INgClass, InputComponentBasicBindings, IValidatorError} from '@ideals/types';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {transitionErrors} from '../animations';
import {InputComponent} from '../input/input.component';

@Component({
  selector: 'ideals-input-control-wrapper',
  templateUrl: './input-control-wrapper.component.html',
  styleUrls: ['./input-control-wrapper.component.scss'],
  exportAs: 'wrapper',
  encapsulation: ViewEncapsulation.None,
  animations: [transitionErrors]
})
export class InputControlWrapperComponent extends InputComponentBasicBindings implements OnInit, AfterContentInit, OnDestroy {
  private static readonly _labelLeftPositionClassNamePrefix = 'ideals-label-';
  private static readonly _invalidControlClassName = 'invalid';
  private static readonly _hasPasswordStateClassName = 'has-password-state';
  private static readonly _validControlClassName = 'valid';
  private static readonly _processingControlClassName = 'processing';
  private static readonly _withSuffixClassName = 'with-suffix';

  @Input()
  public errorList: Array<IValidatorError> = [];

  public destroyed$ = new Subject<void>();
  public focused = false;

  public readonly passwordStates = {
    Weak: 'psw.STATE.weak',
    Strong: 'psw.STATE.strong',
    NotStrong: 'psw.STATE.not_strong_enough'
  };

  constructor(
    @Optional()
    public readonly parentFormGroup: FormGroupDirective,
    @Optional()
    private readonly _inputComponent: InputComponent
  ) {
    super();
  }

  public get hasPasswordError(): boolean {
    if (this.passwordClassName === 'Strong') {
      return this.focused;
    }

    return this.passwordClassName !== '';
  }

  public ngOnInit(): void {
    if (this.parentFormGroup && !this.formControl) {
      this.formControl = this.parentFormGroup.control.get(this.formControlName.toString());
    }
  }

  public ngAfterContentInit(): void {
    if (!this._inputComponent) {
      return;
    }

    this._inputComponent.inputOnFocus
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.focused = true;
      });

    this._inputComponent.inputOnBlur
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.focused = false;
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }

  public get labelClassList(): INgClass {
    return {
      [`${InputControlWrapperComponent._labelLeftPositionClassNamePrefix}${this.labelPosition}`]: true,
      [this.labelCustomClass]: true
    };
  }

  public get inputClassList(): INgClass {
    return {
      [InputControlWrapperComponent._invalidControlClassName]: this.canShowError && !this.focused,
      [InputControlWrapperComponent._hasPasswordStateClassName]: this.hasPasswordError,
      [InputControlWrapperComponent._validControlClassName]: this.canShowSuccess,
      [InputControlWrapperComponent._processingControlClassName]: this.processing,
      [InputControlWrapperComponent._withSuffixClassName]: !!this.suffixTemplate,
      [this.inputCustomClass]: true
    };
  }

  public get showSuffix(): boolean {
    return !!this.suffixClass;
  }

  public get showPrefix(): boolean {
    return !!this.prefixClass;
  }
}
