<ng-container>
  <label class="ideals-label"
         *ngIf="label"
         [for]="id"
         [ngClass]="labelClassList">
    <span>
      {{label}}
      <span *ngIf="isRequired"> *</span>
      <span class="font-weight-normal" *ngIf="isOptional"> {{'common.LABEL.optional' | translate}}</span>
    </span>

    <span class="ideals-label__suffix" *ngIf="labelSuffixTemplate">
     <ng-container *ngTemplateOutlet="labelSuffixTemplate"></ng-container>
    </span>
  </label>
  <div class="ideals-input" [ngClass]="inputClassList">
    <label class="ideals-input-prefix"
           *ngIf="showPrefix"
           [for]="id"
           [ngClass]="prefixClass">
    </label>

    <ng-content></ng-content>

    <label class="ideals-input-suffix"
           *ngIf="showSuffix"
           [for]="id"
           [ngClass]="suffixClass">
    </label>
    <span class="ideals-input-suffix" *ngIf="suffixTemplate">
     <ng-container *ngTemplateOutlet="suffixTemplate"></ng-container>
    </span>
  </div>

  <div class="security-strength"
       *ngIf="hasPasswordError"
       [ngClass]="passwordClassName"
       [@.disabled]="!errorTransitionEnabled"
       @transitionErrors>
    <span>{{passwordStates[passwordClassName] | translate}}</span>
  </div>

  <ng-container *ngFor="let error of errorList">
    <div class="ideals-input-error"
         *ngIf="canShowError && !focused && error.text"
         [@.disabled]="!errorTransitionEnabled"
         @transitionErrors>
      <div>{{error.text | async}}</div>
    </div>
  </ng-container>
</ng-container>
