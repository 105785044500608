import {Directive, DoCheck, ElementRef, forwardRef, HostBinding, Input, Optional, Provider, Self} from '@angular/core';
import {FormGroupDirective, NgControl, NgForm} from '@angular/forms';
import {ErrorStateMatcherService} from '@ideals/services';
import {ErrorStateClass, FormFieldControl} from '@ideals/types';

export const IDEALS_INPUT_VALUE_ACCESSOR: Provider = {
  provide: FormFieldControl,
  useExisting: forwardRef(() => InputDirective),
  multi: true
};

@Directive({
  selector: 'input[idealsInput], ng-select[idealsInput], textarea[idealsInput]',
  exportAs: 'idealsInput',
  providers: [IDEALS_INPUT_VALUE_ACCESSOR],
})

export class InputDirective extends ErrorStateClass implements FormFieldControl<any>, DoCheck {
  @Input()
  public get placeholder(): string {
    return this._placeholder;
  }

  public set placeholder(value: string) {
    this._placeholder = value;
    (this._elementRef.nativeElement as HTMLInputElement).placeholder = this._placeholder;
  }

  @Input()
  public get disabled(): boolean {
    return this._disabled;
  }

  public set disabled(value: boolean) {
    this._disabled = value;
    (this._elementRef.nativeElement as HTMLInputElement).disabled = this._disabled;
  }

  @Input()
  public get required(): boolean {
    return this._required;
  }

  public set required(value: boolean) {
    this._required = value;
  }

  @Input()
  public get type(): string {
    return this._type;
  }

  public set type(value: string) {
    this._type = value || 'text';
    (this._elementRef.nativeElement as HTMLInputElement).type = this._type;
  }

  @Input()
  public get value(): string {
    return this._value;
  }

  public set value(value: string) {
    this._value = value;
    (this._elementRef.nativeElement as HTMLInputElement).value = this._value;
  }

  @Input()
  public get readonly(): boolean {
    return this._readonly;
  }

  public set readonly(value: boolean) {
    this._readonly = value;
  }

  @HostBinding('class.ideals-input-element')
  public readonly directiveClass = true;

  @HostBinding('attr.id')
  @HostBinding('attr.data-ideals-input')
  public get id(): string {
    return this.componentId;
  }

  private _disabled = false;
  private _value: any;
  private _type = 'text';
  private _placeholder = '';
  private _required = false;
  private _readonly = false;

  constructor(
    @Optional() public readonly parentFormGroup: FormGroupDirective,
    @Optional() public readonly parentForm: NgForm,
    @Optional() @Self() public readonly ngControl: NgControl,
    public readonly errorStateMatcher: ErrorStateMatcherService,
    private _elementRef: ElementRef<HTMLInputElement>
  ) {
    super(errorStateMatcher, parentForm, parentFormGroup, ngControl);
  }

  public ngDoCheck(): void {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }
}
