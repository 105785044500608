/* eslint-disable camelcase */
import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, signal, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SearchResult } from 'minisearch';
import { iif, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

import { BODY, PLACEMENT } from '@ideals/types';
import { Utils } from '@ideals/utils';

import { IntercomModule, IntercomService } from '../../../intercom';
import { PopupModule } from '../../../popup';
import { HelpAuthDataService } from '../../help-auth-data.service';
import { HelpService } from '../../help.service';
import { HELP_UTM_CAMPAIGN, HELP_UTM_SOURCE } from '../../models/constants';
import { HelpStore } from '../../store/help.store';
import { HelpArticleComponent } from '../help-article/help-article.component';

const SEARCH_DEBOUNCE_TIME = 500;

enum MatchScore {
  Content = 1,
  Title,
  TitleAndContent,
}

interface SearchHeaderParams {
  count: number;
  query: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    FormsModule,
    HelpArticleComponent,
    IntercomModule,
    PopupModule,
    TranslateModule,
  ],
  selector: 'ideals-help-search',
  standalone: true,
  styleUrls: ['./help-search.component.scss'],
  templateUrl: './help-search.component.html',
})
export class HelpSearchComponent {
  readonly #authDataService = inject(HelpAuthDataService);
  readonly #destroyRef = inject(DestroyRef);
  readonly #helpService = inject(HelpService);
  readonly #helpStore = inject(HelpStore);
  readonly #intercomService = inject(IntercomService);

  readonly #searchQueryChange = new Subject<string>();

  readonly #defaultArticles = this.#helpStore.filteredArticles;
  readonly #searchArticles = toSignal(
    this.#searchQueryChange.pipe(
      takeUntilDestroyed(this.#destroyRef),
      debounceTime(SEARCH_DEBOUNCE_TIME),
      distinctUntilChanged(),
      tap((value) => this.searchQuery.set(value)),
      switchMap((value) => iif(
        () => !!value,
        this.#getSearchArticles(value),
        of(undefined)
      ))
    )
  );

  protected readonly articles = computed(() => this.#searchArticles() ?? this.#defaultArticles());
  protected readonly emptyData = computed(() => !!this.#searchArticles() && this.#searchArticles().length === 0);
  protected readonly searchHeader = computed(() => {
    const searchArticles = this.#searchArticles();

    if (!searchArticles) {
      return 'help_modal.TEXT.popular_topics';
    }

    return searchArticles.length === 0 ? 'help_modal.MSG.no_results' : 'help_modal.MSG.results_count';
  });
  protected readonly searchQuery = signal('');

  protected readonly popupBoundaries = BODY;
  protected readonly popupPlacement = PLACEMENT.BottomStart;

  protected get intercomChat(): boolean {
    return !!this.#intercomService.intercomChat;
  }

  protected get isDownloadBtnVisible(): boolean {
    return this.#authDataService.isLoggedIn;
  }

  protected get searchHeaderParams(): SearchHeaderParams {
    return {
      query: `<span class="query">${this.searchQuery()}</span>`,
      count: this.#searchArticles()?.length ?? 0,
    };
  }

  protected downloadArticles(event: MouseEvent): void {
    event.preventDefault();
    this.#helpService.downloadArticles().subscribe();
  }

  protected onSearchChange(value: string = ''): void {
    this.#searchQueryChange.next(value);
  }

  protected reset(): void {
    this.searchQuery.set('');
    this.#searchQueryChange.next('');
  }

  protected urlWithUTMTags(url: string, recommended?: boolean): string {
    const params = {
      utm_source: HELP_UTM_SOURCE,
      utm_medium: this.#helpStore.appPage(),
      utm_campaign: this.searchQuery() && !recommended ? HELP_UTM_CAMPAIGN.SEARCH_RESULT : HELP_UTM_CAMPAIGN.RECOMMENDED,
    };

    return Utils.objectToQueryString(params, url);
  }

  #countMatchScore(item: SearchResult): void {
    if (item.matchScore) {
      return;
    }

    const fields: Record<string, boolean> = {};
    let matchTitle = false;
    let matchContent = false;

    const matchTitleAndContent = item.terms.some((term) => {
      item.match[term].forEach((field) => {
        fields[field] = true;
      });

      matchTitle = fields.title;
      matchContent = fields.description || fields.body;

      return matchTitle && matchContent;
    });

    item.matchScore = matchTitleAndContent
      ? MatchScore.TitleAndContent
      // eslint-disable-next-line unicorn/no-nested-ternary
      : matchTitle
        ? MatchScore.Title
        : MatchScore.Content;
  }

  #getSearchArticles(query: string): Observable<SearchResult[]> {
    const searchIndex = this.#helpStore.searchIndex();
    const results = searchIndex.search(query).sort((a, b) => {
      this.#countMatchScore(a);
      this.#countMatchScore(b);

      const queryLowerCase = query.toLowerCase().trim();
      const aTitleLowerCase = (a.title as string).toLowerCase();
      const bTitleLowerCase = (b.title as string).toLowerCase();
      const aTitleMatch = aTitleLowerCase.indexOf(queryLowerCase) + 1;
      const bTitleMatch = bTitleLowerCase.indexOf(queryLowerCase) + 1;
      // eslint-disable-next-line unicorn/no-nested-ternary
      const titleMatch = aTitleMatch && bTitleMatch ? aTitleMatch - bTitleMatch : aTitleMatch ? -1 : bTitleMatch ? 1 : 0;

      return titleMatch || b.matchScore - a.matchScore || b.score - a.score;
    });

    this.#helpService.logSearchStatistics(query, results.length).subscribe();

    return of(results);
  }
}
