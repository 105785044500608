import { inject, Injectable } from '@angular/core';

import { LOCAL_STORAGE } from '@ideals/types';

import { StorageService } from './storage.service';

// eslint-disable-next-line @angular-eslint/use-injectable-provided-in
@Injectable()
export class LocalStorageService extends StorageService {
  readonly #localStorage = inject(LOCAL_STORAGE);

  delete(key: string): void {
    this.#localStorage.removeItem(key);
  }

  get(key: string): string | null {
    return this.#localStorage.getItem(key);
  }

  set(key: string, data: string): void {
    this.#localStorage.setItem(key, data);
  }
}
