import { inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import MiniSearch from 'minisearch';
import { CookieService } from 'ngx-cookie-service';
import { combineLatest, filter, of, pipe, switchMap, tap } from 'rxjs';

import { GeoService } from '@ideals/services/geo';
import { APP_CONFIG, AppPage, Cultures, IStringMap, ITimeZone } from '@ideals/types';

import { HelpService } from '../help.service';
import { COUNTRY_CODE_COOKIE, DEFAULT_COUNTRY_CODE, SPACE_OR_PUNCTUATION, SPLIT_HIEROGLYPHS_ONLY } from '../models/constants';
import { IHelpCountry } from '../models/interfaces';
import { Articles, HelpCountries, Videos } from '../models/types';

interface HelpState {
  readonly appPage: AppPage;
  readonly articles: Articles;
  readonly articlesLoading: boolean;
  readonly countries: HelpCountries;
  readonly countriesLoading: boolean;
  readonly countryPhonesMapping: IStringMap;
  readonly filteredArticles: Articles;
  readonly filteredVideos: Videos;
  readonly language: string;
  readonly searchIndex: MiniSearch;
  readonly selectedCountry: IHelpCountry;
  readonly userLocation: ITimeZone;
  readonly videos: Videos;
  readonly videosLoading: boolean;
}

const initialState: HelpState = {
  appPage: undefined,
  articles: undefined,
  articlesLoading: false,
  countries: undefined,
  countriesLoading: false,
  countryPhonesMapping: undefined,
  filteredArticles: undefined,
  filteredVideos: undefined,
  language: undefined,
  searchIndex: undefined,
  selectedCountry: undefined,
  userLocation: undefined,
  videos: undefined,
  videosLoading: false,
};

type Terms = string[];

function tokenizePunctuation(term: string): Terms {
  return term.split(SPACE_OR_PUNCTUATION);
}

function tokenizeBySymbol(term: string): Terms {
  return tokenizePunctuation(term).reduce<string[]>((acc, str) => {
    acc.push(...str.match(SPLIT_HIEROGLYPHS_ONLY));

    return acc;
  }, []);
}

export const HelpStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods((store) => {
    const appConfig = inject(APP_CONFIG);
    const cookieService = inject(CookieService);
    const geoService = inject(GeoService);
    const helpService = inject(HelpService);
    const translateService = inject(TranslateService);

    const loadArticles = rxMethod<void>(
      pipe(
        tap(() => patchState(store, { articlesLoading: true })),
        switchMap(() => helpService.getArticles().pipe(
          tapResponse(
            (articles) => {
              const page = store.appPage();

              patchState(store, {
                articles,
                filteredArticles: articles.filter((item) => item.pages.split(/\s*[\s,]\s*/).includes(page)),
                articlesLoading: false,
              });
            },
            () => {
              patchState(store, { articlesLoading: false });
            },
          )
        ))
      )
    );

    const loadVideos = rxMethod<void>(
      pipe(
        tap(() => patchState(store, { videosLoading: true })),
        switchMap(() => helpService.getVideos().pipe(
          tapResponse(
            (videos) => {
              const page = store.appPage();

              patchState(store, {
                videos,
                filteredVideos: page === 'login' ? [] : videos,
                videosLoading: false,
              });
            },
            () => {
              patchState(store, { videosLoading: false });
            },
          )
        ))
      )
    );

    const loadCountries = rxMethod<void>(
      pipe(
        tap(() => patchState(store, { countriesLoading: true })),
        switchMap(() => {
          return combineLatest([
            store.userLocation()
              ? of(store.userLocation())
              : geoService.getTimeZone().pipe(
                tap((userLocation) => patchState(store, { userLocation })),
              ),
            store.countryPhonesMapping()
              ? of(store.countryPhonesMapping())
              : helpService.getCountryPhonesMapping().pipe(
                tap((countryPhonesMapping) => patchState(store, { countryPhonesMapping })),
              ),
            helpService.getHelpCountries().pipe(
              tap((countries) => patchState(store, { countries })),
            ),
          ]).pipe(
            tapResponse(
              ([userLocation, countryPhonesMapping, countries]) => {
                const countryCode = cookieService.get(COUNTRY_CODE_COOKIE)
                  || countryPhonesMapping[userLocation.countryCode]
                  || DEFAULT_COUNTRY_CODE;
                const selectedCountry = countries.find((country) => country.countryCode === countryCode) || countries[0];

                patchState(store, { selectedCountry, countriesLoading: false });
              },
              () => {
                patchState(store, { countriesLoading: false });
              }
            )
          );
        })
      )
    );

    const setAppPage = (appPage: AppPage): void => {
      patchState(store, { appPage });
    };

    const setSearchIndex = rxMethod<void>(
      pipe(
        switchMap(() => {
          return helpService.getSearchIndex().pipe(
            tap((data) => {
              const searchIndex = new MiniSearch({
                fields: ['title', 'description', 'body'],
                storeFields: ['title', 'url'],
                // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
                tokenize: translateService.currentLang === Cultures.zh_CN
                  ? tokenizeBySymbol
                  : tokenizePunctuation,
                searchOptions: {
                  boost: { title: 10 },
                  prefix: true,
                },
              });

              searchIndex.removeAll();
              searchIndex.addAll(data);
              patchState(store, { searchIndex });
            })
          );
        })
      )
    );

    return {
      loadHelpData: rxMethod<{ language: string; }>(
        pipe(
          filter(({ language }) => {
            const shouldLoad = store.language() !== language;

            patchState(store, { language });

            return shouldLoad;
          }),
          tap(() => {
            if (!store.appPage()) {
              setAppPage(appConfig.appPage);
            }

            loadCountries();
            loadArticles();
            loadVideos();

            if (!store.searchIndex()) {
              setSearchIndex();
            }
          }),
        )
      ),
      selectCountry: (country: IHelpCountry) => {
        patchState(store, { selectedCountry: country });
      },
      setAppPage,
    };
  })
);
