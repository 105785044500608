import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {InputControlWrapperComponent} from './input-control-wrapper.component';

@NgModule({
  declarations: [InputControlWrapperComponent],
  imports: [CommonModule, TranslateModule],
  exports: [InputControlWrapperComponent]
})
export class InputControlWrapperModule {}
