import { IHelpCountry } from '../interfaces/help-country.interface';

export class HelpCountry implements IHelpCountry {
  readonly contacts: string;
  readonly countryCode: string;
  readonly countryName: string;
  readonly phoneCode: string;
  readonly regionName: string;

  constructor(data: IHelpCountry) {
    this.regionName = data.regionName;
    this.countryName = data.countryName;
    this.phoneCode = data.phoneCode;
    this.countryCode = data.countryCode;
    this.contacts = data.contacts;
  }
}
