import { APP_BASE_HREF, DOCUMENT } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, InjectionToken } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, TitleStrategy, withComponentInputBinding } from '@angular/router';

import { ClarityService } from '@ideals/services/clarity';
import { ColorSchemeService } from '@ideals/services/color-scheme';
import { FeatureToggleModule } from '@ideals/services/feature-toggle';
import { LOCALIZATION_BASE_URL, provideRootTranslate } from '@ideals/services/root-translate';
import { APP_CONFIG, LOCATION } from '@ideals/types';

import { environment } from '../environments/environment';

import { baseHrefInterceptor } from './core/interceptors/base-href';
import { serverErrorsInterceptor } from './core/interceptors/server-errors';
import { userStateErrorsInterceptor } from './core/interceptors/user-state-errors';
import { provideErrorHandler } from './core/services/error-handler';
import { LocalStorageService, StorageService } from './core/services/storage';
import { PageTitleStrategy } from './core/strategies/page-title';

import { appRoutes } from './app.routes';

const BASED_ON_BROWSER_LANGUAGE = new InjectionToken<boolean>('BASED_ON_BROWSER_LANGUAGE');

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      FeatureToggleModule.forRoot(environment.unleashConfiguration)
    ),
    provideHttpClient(
      withInterceptors([
        baseHrefInterceptor,
        serverErrorsInterceptor,
        userStateErrorsInterceptor,
      ]),
    ),
    provideAnimations(),
    provideRootTranslate(),
    provideRouter(appRoutes, withComponentInputBinding()),
    provideErrorHandler(),
    {
      provide: APP_CONFIG,
      useValue: environment,
    },
    {
      provide: APP_BASE_HREF,
      useFactory: (location: Location) => location.origin,
      deps: [LOCATION],
    },
    {
      provide: TitleStrategy,
      useClass: PageTitleStrategy,
    },
    {
      provide: StorageService,
      useClass: LocalStorageService,
    },
    {
      provide: BASED_ON_BROWSER_LANGUAGE,
      useValue: true,
    },
    {
      provide: LOCALIZATION_BASE_URL,
      useFactory: (document: Document) => document.baseURI,
      deps: [DOCUMENT],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (clarityService: ClarityService) => {
        return () => clarityService.initialize();
      },
      deps: [ClarityService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (colorSchemeService: ColorSchemeService) => {
        return () => colorSchemeService.initialize();
      },
      deps: [ColorSchemeService],
      multi: true,
    },
  ],
};
