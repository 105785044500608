import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Optional,
  Output,
  Provider,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {FormGroupDirective, NG_VALUE_ACCESSOR} from '@angular/forms';
import {InputTypes, PersistentInputComponent} from '@ideals/types';

export const INPUT_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputComponent),
  multi: true
};

@Component({
  selector: 'ideals-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [INPUT_CONTROL_VALUE_ACCESSOR],
  encapsulation: ViewEncapsulation.None
})
export class InputComponent extends PersistentInputComponent implements AfterViewInit {
  @ViewChild('idealsInput')
  public inputEl: ElementRef;

  @Output()
  public inputOnFocus = new EventEmitter<undefined>();

  @Output()
  public inputOnBlur = new EventEmitter<undefined>();

  constructor(
    @Optional() public readonly parentFormGroup: FormGroupDirective,
    private readonly _renderer: Renderer2,
    private readonly _changeDetectorRef: ChangeDetectorRef
  ) {
    super(parentFormGroup, _changeDetectorRef);
  }

  public ngAfterViewInit(): void {
    if (this.isFocused) {
      setTimeout(() => {
        if (this.mask) {
          this.inputEl.nativeElement.click();
        } else {
          this.inputEl.nativeElement.focus();
          this.inputEl.nativeElement.select();
        }
      }, 0);
    }

    if (this.autoComplete) {
      this._renderer.setAttribute(this.inputEl.nativeElement, 'autocomplete', this.autoComplete);
    }
  }

  public get isInput(): boolean {
    return this.type !== InputTypes.TextArea;
  }

  public inputFocus(): void {
    this.inputOnFocus.emit();
  }

  public inputBlur(): void {
    this.inputOnBlur.emit();
  }

  public focus(): void {
    setTimeout(() => {
      this.inputEl.nativeElement.focus();
    }, 0);
  }

  public blur(): void {
    setTimeout(() => {
      this.inputEl.nativeElement.blur();
    }, 0);
  }
}
